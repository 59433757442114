<template>
  <div class="main mainJituan">
    <user-data @userData="userData"></user-data>
    <dia-logliu :show="dailogshow" :options="dailogoptions"></dia-logliu>

    <loading-cmp v-if="showLoad"></loading-cmp>
    <div
      class="scrollDiv"
      v-if="showNoDate"
      style="margin-top: 10px; overflow: scroll; padding: 30px 30px 0"
    >
      <div v-show="!searchUserStatus">
        <div v-if="showUserRead" class="userReport">
          <div style="font-size: 20px; margin-bottom: 14px" class="wordSpacing">
            尊敬的集团领导{{ wxUser["name"] }}:
          </div>
          <div style="text-indent: 2em; margin: 8px 0" class="wordSpacing">
            您好，感谢您一如既往的指导和关注广州铁路企业号。
          </div>
          <div style="text-indent: 2em; margin: 8px 0" class="wordSpacing">
            本周期，平台访问量
            <span>{{ deptReadInfo["showTotalNum"] }}</span> 次，活跃度<span
              >{{ deptReadInfo["showHuoyueAll"] }}，</span
            >环比分别<span v-if="totalNumValue > 0">上升</span>
            <span v-else>下降</span>
            <span>{{ totalNumRate }}%</span>、<span
              v-if="
                (huoyueduValue > 0 && totalNumValue < 0) ||
                (huoyueduValue < 0 && totalNumValue > 0)
              "
            >
              <span v-if="huoyueduValue > 0">上升</span><span v-else>下降</span>
            </span>
            <span>{{ huoyueduRate }}%</span>。平台累计阅读文章
            <span>
              {{
                deptPubContent["pubLjContent"] + deptPubContent["pubZdContent"]
              }}篇</span
            >，阅读量<span>{{ deptReadInfo["showCount"] }}</span
            >，阅读人次 <span>{{ deptReadInfo["showPerCount"] }}</span
            >，其中集团内容<span>{{ deptPubContent["pubLjContent"] }}篇</span
            >，单位内容<span>{{ deptPubContent["pubZdContent"] }} 篇</span>。
          </div>
          <div style="text-indent: 2em">
            平台累计发文<span
              >{{
                deptPubContent["pubLjContent"] + deptPubContent["pubZdContent"]
              }}篇</span
            >，点赞<span>{{ deptReadInfo["showLike"] }}个</span>、分享<span
              >{{ deptReadInfo["showCount"] }}篇</span
            >、留言<span>{{ deptReadInfo["showComment"] }}条</span>。
            广铁头条累计发文<span>{{ deptPubContent["pubLjContent"] }}篇</span>
            、点赞<span>{{ deptReadInfo["showLike_lj"] }}个</span>、分享<span
              >{{ deptReadInfo["showShare_lj"] }}篇</span
            >、留言<span>{{ deptReadInfo["showComment_lj"] }}条</span
            >；单位发文<span>{{ deptPubContent["pubZdContent"] }}篇</span>
            、点赞<span>{{ deptReadInfo["showLike_zd"] }}个</span>、分享<span
              >{{ deptReadInfo["showShare_zd"] }}篇</span
            >、留言<span>{{ deptReadInfo["showComment_zd"] }}条</span>。
          </div>
          <div style="text-indent: 2em; margin: 8px 0">
            请您继续为企业号提出宝贵的意见和建议，您的指导为企业号的发展指明了前进方向!
          </div>
        </div>
      </div>
    </div>
    <div v-if="!showNoDate" style="margin-top: 10px">数据处理中</div>
  </div>
</template>

<script>
// @ is an alias to /src
// https://wx-stats.gtrmt.cn/?path=deptReport&deptId=3005&e=1673107200000&s=1672588800000#/deptReport
// https://wx-stats.gtrmt.cn/?path=deptReport&s=1672588800000&e=1673107200000/#/deptReport
import _ from "lodash";
import moment from "moment";
import userData from "../../components/userData.vue";
import { statsPost, getRadioDate } from "../../api/httpApi";
import wxLoginApi from "../../api/wxLoginApi";
import commonTop from "../../components/commonTop.vue";
import noPassCom from "../../components/noPassCom.vue";
import diaLogliu from "../../components/dialogLiu.vue";
import loadingCmp from "../../components/loadingCmpWhite.vue";
import deptTree from "../../components/deptTree.vue";
import userList from "../../components/userList.vue";
import descCom from "../../components/descCom.vue";
import pieChart from "../../components/pieChart.vue";
import huanBiTongBiCom from "../../components/huanBiTongBiCom.vue";

export default {
  name: "jtReport",
  components: {
    loadingCmp,
    noPassCom,
    deptTree,
    diaLogliu,
    userList,
    commonTop,
    descCom,
    pieChart,
    huanBiTongBiCom,
    userData,
  },

  data() {
    return {
      deptPubContent: {},
      deptReadInfo: {
        name: "",
        Positive: 0,
        Neutral: 0,
        Negative: 0,
        count: 0,
        like: 0,
        share: 0,
        comment: 0,
        commentView: 0,
        count_lj: 0,
        count_zd: 0,
        like_lj: 0,
        like_zd: 0,
        share_lj: 0,
        share_zd: 0,
        comment_lj: 0,
        comment_zd: 0,
        perCount: 0,
        totalNum: 0,
        ljNum: 0,
        ljTime_s: 0,
        zdNum: 0,
        zdTime_s: 0,
        jzNum: 0,
        jzTime_s: 0,
        signIntegral: 0,
        clickIntegral: 0,
        readingTimeIntegral: 0,
        readingVideoIntegral: 0,
        readingVideoTimeCountIntegral: 0,
        likeIntegral: 0,
        shareIntegral: 0,
        commentIntegral: 0,
        integralAll: 0,
        wxRun: 0,
      },
      deptReadOldInfo: {
        Positive: 0,
        Neutral: 0,
        Negative: 0,
        count: 0,
        like: 0,
        share: 0,
        comment: 0,
        commentView: 0,
        count_lj: 0,
        count_zd: 0,
        like_lj: 0,
        like_zd: 0,
        share_lj: 0,
        share_zd: 0,
        comment_lj: 0,
        comment_zd: 0,
        perCount: 0,
        totalNum: 0,
        ljNum: 0,
        ljTime_s: 0,
        zdNum: 0,
        zdTime_s: 0,
        jzNum: 0,
        jzTime_s: 0,
        signIntegral: 0,
        clickIntegral: 0,
        readingTimeIntegral: 0,
        readingVideoIntegral: 0,
        readingVideoTimeCountIntegral: 0,
        likeIntegral: 0,
        shareIntegral: 0,
        commentIntegral: 0,
        integralAll: 0,
        wxRun: 0,
      },
      readContentRate: 0,

      userLikeLabel: [],
      optionHuanBiRadio: {},
      optionTongBiRadio: {},
      huanbiTongBiDetail: {},

      userInfo: {},

      isUserPm: true, // 是否有权限
      showLoad: true, // 显示加载框

      searchUserStatus: false, // 显示用户列表 | 显示编辑用户
      toSearchData: false, // 用户组件是否查询

      detailInfo: {
        头条平台阅读时长: 0,
        单位平台阅读时长: 0,
        矩阵阅读时长: 0,
        总阅读时长: 0,

        countNum: 0,
        shareNum: 0,
        commentNum: 0,
        likeNum: 0,
        totalNum: 0, // 进入次数

        分享总积分: 0, // shareIntegral
        平台总积分: 0, // integralAll
        阅读总积分: 0, // clickIntegral
        点赞总积分: 0, // likeIntegral
        签到总积分: 0, //signIntegral
        视频阅读总积分: 0, // readingVideoTimeCountIntegral
        视频阅读时长总积分: 0, //  readingVideoIntegral
        评论总积分: 0, //  commentIntegral

        阅读时长总积分: 0, //readingTimeIntegral
      },

      curUserSeriesReadObj: {
        countNum: [],
        likeNum: [],
        commentNum: [],
        totalNum: [],
        shareNum: [],
        zdtime_h: [],
        ljtime_h: [],
        alltime_h: [],
      },

      dailogshow: false, // 提示框显示
      duration: 1000,
      dailogoptions: {
        content: "",
        autoClose: true,
        textColor: "#ccc",
      }, // 提示框选项

      startDate: "2022-01-01",
      endDate: "2022-02-31",
      wxUser: {},
      dataList: [],
      chartData: [],
      chartXAxis: [], // 积分 横坐标
      readTimeData: [], // 阅读时长曲线

      chartReadXAxis: [], // 阅读横坐标
      showNoDate: true,
      dataTimer: null,
      contentStr: "",
      readRate: 0,
      totalNumValue: 0,
      totalNumRate: 0, // 平台访问量环比
      huoyueduRate: 0, // 活跃度环比
      huoyueduValue: 0,
      readOldRate: 0,
      readPerCount: 0,
      showText: "",
      showUserRead: false,
      deptIdArr: [],
    };
  },

  methods: {
    getRadio(newVal, oldVal, fixNum) {
      let _fixNum = fixNum || 2;
      if (fixNum == 0) {
        _fixNum = 0;
      }
      let _differenceValue = 0;
      if (oldVal) {
        _differenceValue = ((newVal - oldVal) / oldVal) * 100;
      }
      return _differenceValue;
    },
    dealNum(num) {
      let _curNum = num;
      if (_curNum > 10000) {
        _curNum = (_curNum / 10000).toFixed(1);
        return `${_curNum}万`;
      }

      return _curNum;
    },

    async initData() {
      let { startDate, endDate } = getRadioDate(this.startDate, this.endDate);
      // 查单位数据
      await this.getDeptData(this.startDate, this.endDate, "curData");
      await this.getDeptData(startDate, endDate, "radioData");
      // 查发文
      await this.getDeptContentNum(
        this.startDate,
        this.endDate,
        "curData",
        "lj"
      ); // new

      await this.getDeptContentNum(
        this.startDate,
        this.endDate,
        "curData",
        "zd"
      ); // new

      this.showUserRead = true;

      this.deptReadInfo["showTotalNum"] = this.dealNum(
        this.deptReadInfo["totalNum"]
      );

      this.deptReadInfo["showCount"] = this.dealNum(this.deptReadInfo["count"]);
      this.deptReadInfo["showLike"] = this.dealNum(this.deptReadInfo["like"]);
      this.deptReadInfo["showComment"] = this.dealNum(
        this.deptReadInfo["comment"]
      );
      this.deptReadInfo["showshare"] = this.dealNum(this.deptReadInfo["share"]);

      this.deptReadInfo["showCount_zd"] = this.dealNum(
        this.deptReadInfo["count_zd"]
      );
      this.deptReadInfo["showLike_zd"] = this.dealNum(
        this.deptReadInfo["like_zd"]
      );
      this.deptReadInfo["showComment_zd"] = this.dealNum(
        this.deptReadInfo["comment_zd"]
      );
      this.deptReadInfo["showShare_zd"] = this.dealNum(
        this.deptReadInfo["share_zd"]
      );

      this.deptReadInfo["showCount_lj"] = this.dealNum(
        this.deptReadInfo["count_lj"]
      );
      this.deptReadInfo["showLike_lj"] = this.dealNum(
        this.deptReadInfo["like_lj"]
      );
      this.deptReadInfo["showComment_lj"] = this.dealNum(
        this.deptReadInfo["comment_lj"]
      );
      this.deptReadInfo["showShare_lj"] = this.dealNum(
        this.deptReadInfo["share_lj"]
      );

      this.deptReadInfo["showPerCount"] = this.dealNum(
        this.deptReadInfo["perCount"]
      );

      // 计算活跃度环比
      const huoyueAll =
        this.deptReadInfo.count +
        this.deptReadInfo.comment +
        this.deptReadInfo.like +
        this.deptReadInfo.share;
      this.deptReadInfo["showHuoyueAll"] = this.dealNum(huoyueAll);

      // 计算访问量环比
      this.totalNumValue = this.getRadio(
        this.deptReadInfo.totalNum,
        this.deptReadOldInfo.totalNum
      );

      this.totalNumRate = this.totalNumValue.toFixed(1);

      const huoyueOldAll =
        this.deptReadOldInfo.count +
        this.deptReadOldInfo.comment +
        this.deptReadOldInfo.like +
        this.deptReadOldInfo.share;
      this.huoyueduValue = this.getRadio(huoyueAll, huoyueOldAll);
      this.huoyueduRate = this.huoyueduValue.toFixed(1);

      // 处理显示字符串
      // 计算文章环比

      // if (this.deptReadInfo["yjh"]) {
      //   this.readPerCount = (
      //     this.deptReadInfo["perCount"] / this.deptReadInfo["yjh"]
      //   ).toFixed(2);
      // }
    },
    async getDeptData(startDate, endDate, type) {
      try {
        if (this.dataTimer) {
          clearTimeout(this.dataTimer);
        }

        this.showLoad = true;

        const ret = await statsPost("/report/deptReport", {
          startDate,
          endDate,
          deptId: this.deptIdArr[0],
        });

        if (ret.errMsg) {
          if ("等待中" == ret.errMsg || "进行中" == ret.errMsg) {
            if (this.dataTimer) {
              clearTimeout(this.dataTimer);
            }

            this.dataTimer = setTimeout(async () => {
              this.showLoad = false;
              await this.getDeptData(startDate, endDate, type);
            }, 2000);
          } else {
            this.showLoad = false;
          }
          return;
        }
        if (ret.ret) {
          if (type == "radioData") {
            this.deptReadOldInfo = ret.ret;
          } else {
            this.deptReadInfo = ret.ret;
            const ljTime_s = ret.ret.ljTime_s || 0;
            const zdTime_s = ret.ret.zdTime_s || 0;
            const jzTime_s = ret.ret.jzTime_s || 0;
            const allTime_s = ljTime_s + zdTime_s + jzTime_s;
            this.deptReadInfo["allTime_s"] = formatSecond(allTime_s, "minute");
          }
        }

        this.showLoad = false;
      } catch (error) {
        this.showLoad = false;
        if (this.dataTimer) {
          clearTimeout(this.dataTimer);
        }
      }
    },
    async getDeptContentNum(startDate, endDate, type, zdLj) {
      try {
        this.showLoad = true;
        // TODO: 建议集团报告时，发文原创查询部门id为'lj'，单位阅读量、阅读lv使用路局文章的数
        let deptId = 1;
        if ("lj" == zdLj) {
          deptId = "lj";
        }
        const ret = await statsPost("/report/getDeptContentNum", {
          startDate,
          endDate,
          deptId,
        });

        if (ret.ret) {
          if (type == "radioData") {
            // 环比
            this.deptPubContent["pubOldZdContent"] = ret.ret.conentNumLen; // old推文总数
            this.deptPubContent["pubOldZdYuanchuang"] = ret.re.originalNum; // //原创数
          } else {
            if ("lj" == zdLj) {
              this.deptPubContent["pubLjContent"] = ret.ret.conentNumLen;
              this.deptPubContent["pubLjYuanchuang"] = ret.ret.originalNum;
            } else {
              this.deptPubContent["pubZdContent"] = ret.ret.conentNumLen;
              this.deptPubContent["pubZdYuanchuang"] = ret.ret.originalNum;
            }
          }
        }
        this.showLoad = false;
      } catch (error) {
        this.showLoad = false;
        if (this.dataTimer) {
          clearTimeout(this.dataTimer);
        }
      }
    },

    // 截取字符串
    getQueryString(name) {
      const url = window.location.href;
      const r = url.match(
        // eslint-disable-next-line
        new RegExp(`([\?|&])${name}=([^|&||#|\/]*)(&|#|\/|$)`)
      );
      if (r !== null) {
        const str = decodeURI(r[2]);
        const pos = str.indexOf("#/");
        if (pos >= 0) {
          return str.substr(0, pos);
        }
        return str;
      }
      return null;
    },
    async userData() {
      console.log("userData ok ----------", this.showLoad, this.deptIdArr);
      // if (!this.showLoad) {
      // 判断用户权限，当用户无权限，查询用户自己所在部门的4级
      if (this.deptIdArr.length == 0) {
        // 查询用户所在部门
        const userinfo = await statsPost("/dept/getUserDept");
        const user3Dept = _.get(userinfo, ["parentDept", 2], "");
        this.deptIdArr = user3Dept ? [user3Dept] : [userinfo["parentDept"][2]];
      }

      this.initData();
      this.showLoad = false;
      // }
    },
  },

  async created() {
    // 取周期
    const startDate = this.getQueryString("s");
    const endDate = this.getQueryString("e");

    this.startDate = startDate
      ? moment(parseInt(startDate)).format("YYYY-MM-DD")
      : "";
    this.endDate = endDate
      ? moment(new Date(parseInt(endDate))).format("YYYY-MM-DD")
      : "";

    if (!this.startDate || !this.endDate) {
      this.showNoDate = false;
    }

    const deptId = this.getQueryString("deptId");

    wxLoginApi.onReady(async () => {
      try {
        this.wxUser = wxLoginApi.getUser();
        // 如果置顶ID 查指定的id
        this.deptIdArr = [1];

        // this.initData();
        this.showLoad = true;
      } catch (error) {
        console.log("-----init 0", error);
        this.showLoad = false;
      }
    });
  },
  computed: {
    optionUserJiefen() {
      let optionHour = {
        title: {
          left: "center",
          text: "积分曲线",
        },
        axisLabel: {
          roate: 45,
          interval: 0,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.chartXAxis,
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
        ],
        series: [
          {
            showSymbol: false,
            name: "积分",
            type: "line",
            data: this.chartData,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      };
      return optionHour;
    },

    optionUserReadTime() {
      let optionHour = {
        axisLabel: {
          roate: 45,
        },
        title: {
          left: "center",
          text: "阅读时长(秒)",
        },
        grid: {
          left: "3%",
          right: "4%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          // type: "line",
          type: "category",
          // type: "curve",
          data: this.chartReadXAxis,
        },
        yAxis: {
          type: "value",
        },

        series: [
          {
            data: this.curUserSeriesReadObj.alltime_h,
            type: "line",
            showBackground: true,
            itemStyle: { color: "#83bff6" },
          },
        ],
      };
      return optionHour;
    },
    deptCountOption() {
      let option = {
        axisLabel: {
          roate: 45,
          interval: 0,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.chartReadXAxis,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },

        series: [
          {
            name: "阅读量",
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的
            type: "line",
            showBackground: true,
            data: this.curUserSeriesReadObj.countNum,
          },

          {
            name: "点赞量",
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的
            type: "line",
            showBackground: true,
            data: this.curUserSeriesReadObj.likeNum,
          },
          {
            name: "分享",
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的
            type: "line",
            showBackground: true,
            data: this.curUserSeriesReadObj.shareNum,
          },
          {
            name: "评论",
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的
            type: "line",
            showBackground: true,
            data: this.curUserSeriesReadObj.commentNum,
          },
          {
            name: "进入次数",
            smooth: true, //这句就是让曲线变平滑的
            symbol: "none", //这句就是去掉点的
            type: "line",
            showBackground: true,
            data: this.curUserSeriesReadObj.totalNum,
          },
        ],
      };
      return option;
    },
  },
  watch: {},
};
</script>

<style scoped>
.mainJituan {
  color: white;
  width: 100vw;
  height: 100vh;
  background: url("../../assets/jtReport.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  line-height: 28px;
}
.wordSpacing {
  letter-spacing: 2px;
}
.userReport {
  font-size: 16px;
}
.userReport span {
  letter-spacing: 0;
}
.userReport {
  padding: 10px;
  text-align: left;
  font-weight: bold;
}
.userBaseInfo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  padding: 10px;
  text-align: left;
}
.userBaseInfoItem {
  flex: 1;
  width: 50%;
  min-width: 50%;
  max-width: 50%;
  text-align: left;
  padding: 8px 0;
  font-weight: bold;
}
.userBaseInfoItem .descColor {
  color: white;
}
.userBaseInfoItem .valColor {
  color: white;
}

.deptMainTop {
  text-align: center;
  /* color: white; */
  padding: 15px 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5px;
  grid-template-rows: repeat(1, 1fr);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.deptMainTop .box-item {
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  font-size: 11px;
  position: relative;
  font-weight: bold;
}
.deptMainTop .box-item span {
  flex: 1;
  color: #b57464;
  font-size: 16px;
}

.deptMainTop .box-item .numColr,
.numColor {
  font-size: 16px;
  font-weight: bold;
  color: #866060;
}
.deptMainTop .box-item .descColor,
.descColor {
  color: #bbb;
  overflow: hidden;
  font-size: 10px;
}

.labelSty {
  display: inline-block;
  color: #de6e6a;
  border: 1px solid rgb(134 96 96 / 23%);
  padding: 1px 3px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
  margin: 0 3px 0 0;
}
.labelTitle {
  display: inline-block;
  color: #866060;
  padding: 3px 4px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: bold;
}
</style>
